<template>
  <loading v-if="isHttpData" style="margin-top: 210px"></loading>
  <div class="invoice" v-else>
    <div class="invoice-box" v-if="!showPayment">
      <div class="hint">
        <el-alert
          type="warning"
          :closable="false"
          description="为保障流程正常,须提供以下信息,对此信息我们会严格遵循用户个人信息保护及隐私政策协议,请您放心填写。"
          show-icon
        >
        </el-alert>
      </div>
      <div class="message">
        <van-field
          v-model="form.username"
          label="姓名"
          placeholder="请输入姓名"
        />
        <van-field
          v-model="form.idnumber"
          label="身份证"
          placeholder="请输入身份证"
        />
        <van-field
          v-model="form.mobile"
          type="digit"
          label="手机号"
          placeholder="请输入电话"
        />
      </div>
      <div class="uploading-img">
        <el-upload
          class="upload-demo"
          action="/api/v1/micro/qiniu/upload_images"
          :before-upload="beforeUpload"
          :on-success="handleAvatarSuccess"
          :data="{ storage_space: '1' }"
          :show-file-list="false"
        >
          <el-button size="small" type="primary">上传支付凭证</el-button>
        </el-upload>
        <p v-if="!payImg">示例图片</p>
        <img
          class="transaction"
          v-if="payImg"
          @click="imagePreview(payImg)"
          :src="payImg"
          alt=""
        />
        <img class="transaction" v-else src="../assets/pay/pay.jpg" alt="" />
        <div class="return-pay" @click="goRegPayment">
          <i class="el-icon-arrow-left"></i>
          <span>返回付款</span>
        </div>
      </div>
      <div class="invoice-form">
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item name="1" :is-link="false">
            <template #title>
              <div class="added-title">
                <div class="flex-between">
                  <span>开具发票</span>
                  <van-radio-group v-model="activeName">
                    <van-radio name="1" checked-color="#00ccb0"></van-radio>
                  </van-radio-group>
                </div>
                <div class="position-box"></div>
              </div>
            </template>
            <div class="added-form">
              <van-collapse v-model="form.bill_type" accordion>
                <van-collapse-item name="1" :is-link="false">
                  <template #title>
                    <div class="flex-between">
                      <span>增值税普通发票</span>
                      <van-radio-group v-model="form.bill_type">
                        <van-radio name="1" checked-color="#00ccb0"></van-radio>
                      </van-radio-group>
                      <div class="position-box"></div>
                    </div>
                  </template>
                  <div class="bankcard-content">
                    <div class="color-box">
                      <van-field
                        v-model="bill.company_name"
                        label="单位名称"
                        placeholder="请输入单位名称"
                      />
                      <van-field
                        v-model="bill.taxpayer_number"
                        label="纳税人识别号"
                        placeholder="请输入纳税人识别号"
                      />
                      <van-field
                        v-model="bill.address"
                        label="地址"
                        placeholder="请输入地址"
                      />
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="2" :is-link="false">
                  <template #title>
                    <div class="flex-between">
                      <span>增值税专用发票</span>
                      <van-radio-group v-model="form.bill_type">
                        <van-radio name="2" checked-color="#00ccb0"></van-radio>
                      </van-radio-group>
                      <div class="position-box"></div>
                    </div>
                  </template>
                  <div class="bankcard-content">
                    <div class="color-box">
                      <van-field
                        v-model="bill_content.company_name"
                        label="单位名称"
                        placeholder="请输入单位名称"
                      />
                      <van-field
                        v-model="bill_content.taxpayer_number"
                        label="纳税人识别号"
                        placeholder="请输入纳税人识别号"
                      />
                      <van-field
                        v-model="bill_content.account_bank"
                        label="开户行"
                        placeholder="请输入开户行"
                      />
                      <van-field
                        v-model="bill_content.bank_account"
                        type="digit"
                        label="银行账号"
                        placeholder="请输入银行账号"
                      />
                      <van-field
                        v-model="bill_content.address"
                        label="地址"
                        placeholder="请输入地址"
                      />
                      <van-field
                        v-model="bill_content.mobile"
                        type="digit"
                        label="电话"
                        placeholder="请输入电话"
                      />
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </div>
          </van-collapse-item>
        </van-collapse>
        <!-- <Support style="margin-top: 20px"></Support> -->
      </div>
    </div>
    <div class="Payment-box" v-if="showPayment">
      <img class="accomplish" src="../assets/pay/wancheng.svg" alt="" />
      <div class="barcode-cg">支付成功</div>
      <img class="barcode" src="../assets/pay/txm.svg" alt="" />
      <img class="barcode-img" src="../assets/pay/tiaoxinma.png" alt="" />
      <span>凭证号：{{ payment_voucher }}</span>
      <p class="one-p">入场时出示此凭证号</p>
      <p class="tow-p">请截屏保存使用</p>
      <p class="p-color" @click="del_payment_voucher">信息有误，注销凭证</p>
      <Support class="invoice_support"></Support>
    </div>
    <div class="have-paid-btn" @click="paymentSuccess" v-if="!showPayment">
      <van-button>我确认已完成转账</van-button>
    </div>
    <sideBar></sideBar>
  </div>
</template>

<script>
import Support from "@/components/support";
import sideBar from "@/components/sidebar";
import Loading from "@/components/loading/loading";
import { ImagePreview } from "vant"; // 引入Vant图片预览组件
export default {
  data() {
    return {
      showPayment: false,
      activeName: "",
      payImg: "",
      form: {
        site_id: "",
        username: "",
        idnumber: "",
        mobile: "",
        image_id: "",
        bill_type: "",
        bill_content: "",
      },
      bill: {
        company_name: "",
        taxpayer_number: "",
        address: "",
      },
      bill_content: {
        company_name: "",
        taxpayer_number: "",
        address: "",
        account_bank: "",
        bank_account: "",
        mobile: "",
      },
      payImgName: "",
      payment_voucher: "12345678",
      isHttpData: true,
    };
  },
  components: {
    sideBar,
    Support,
    Loading,
  },
  watch: {
    activeName(val, old) {
      if (val) {
        this.form.bill_type = "1";
      } else {
        this.form.bill_type = "";
      }
    },
  },
  async created() {
    const res = await this.$store.dispatch("get_user_certificate", {
      site_id: this.$route.query.site_id,
    });
    if (res.data.code == 200) {
      if (res.data.data.payment_voucher) {
        this.showPayment = true;
        this.payment_voucher = res.data.data.payment_voucher;
      }
    }
    this.isHttpData = false;
  },
  methods: {
    imagePreview(url) {
      let img = [];
      img.push(url);
      ImagePreview({
        images: img, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: false, //是否开启循环播放
        closeable: true, // 显示关闭按钮
      });
    },
    handleAvatarSuccess(res, e) {
      if (res.code == 200) {
        this.$toast("上传成功");
      } else {
        this.$toast("上传失败");
      }
      this.form.image_id = res.data[this.payImgName].id;
      this.payImg = res.data[this.payImgName].url;
    },
    changeUpload(file) {
      let inputDom = document.getElementsByClassName("el-upload__input")[0];
      const isSize = file.size / 1024 / 1024 < 2;
      const limittypearr = ["jpg", "gif", "png", "jpeg", "webp"];
      const limittye = file.raw.type.split("/")[1];
      if (!limittypearr.includes(limittye)) {
        this.$notify({ message: "上传图片格式不正确！", type: "error" });
        return false;
      }
      if (!isSize) {
        this.$notify({
          message: "上传图片不能超过2M",
          type: "error",
        });
        return false;
      }
      this.payImgName = file.raw.name;
      let formData = new FormData();
      formData.append("files", file.raw);
      formData.append("storage_space", 1);
      this.$store.dispatch("upload_images", formData).then((res) => {
        if (res.data.code == 200) {
          this.form.image_id = res.data.data[this.payImgName].id;
          this.payImg = res.data.data[this.payImgName].url;
          this.$toast("上传成功");
          inputDom.value = "";
        } else {
          this.$toast("上传失败");
          inputDom.value = "";
        }
      });
    },
    beforeUpload(file) {
      const limittypearr = ["jpg", "gif", "png", "jpeg", "webp"];
      const isLt2M = file.size / 1024 / 1024 / 1024 / 1024 / 1024 < 2;
      const limittye = file.type.split("/")[1];
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }
      if (!limittypearr.includes(limittye)) {
        this.$message.error("上传图片格式不正确！");
        return false;
      }
      this.payImgName = file.name;
      return true;
    },
    emptyForm() {
      this.form.username = "";
      this.form.idnumber = "";
      this.form.mobile = "";
      this.form.image_id = "";
      this.payImg = "";
      this.bill.company_name = "";
      this.bill.taxpayer_number = "";
      this.bill.address = "";
      this.bill_content.company_name = "";
      this.bill_content.taxpayer_number = "";
      this.bill_content.account_bank = "";
      this.bill_content.bank_account = "";
      this.bill_content.address = "";
      this.bill_content.mobile = "";
      this.form.bill_content = "";
      this.activeName = "";
      this.form.bill_type = "";
    },
    async paymentSuccess() {
      var pattern = /\d{17}[\d|x]|\d{15}/;
      var reg =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      this.form.site_id = this.$route.query.site_id;
      if (!this.form.username) {
        this.$toast("请输入姓名");
        return;
      } else if (!this.form.idnumber) {
        this.$toast("请输入身份证号");
        return;
      } else if (!pattern.test(this.form.idnumber)) {
        this.$toast("请输入正确的身份证号");
        return;
      } else if (!this.form.mobile) {
        this.$toast("请输入电话");
        return;
      } else if (!reg.test(this.form.mobile)) {
        this.$toast("请输入正确格式的电话");
        return;
      } else if (!this.form.image_id) {
        this.$toast("请上传支付凭证");
        return;
      } else {
        if (this.form.bill_type) {
          if (this.form.bill_type == 1) {
            if (!this.bill.company_name) {
              this.$toast("请输入单位名称");
              return;
            } else if (!this.bill.taxpayer_number) {
              this.$toast("请输入纳税人识别号");
              return;
            } else if (!this.bill.address) {
              this.$toast("请输入地址");
              return;
            } else {
              this.form.bill_content = JSON.stringify(this.bill);
            }
          } else {
            this.form.bill_content = null;
            this.form.bill_content = this.bill_content;
            if (!this.bill_content.company_name) {
              this.$toast("请输入单位名称");
              return;
            } else if (!this.bill_content.taxpayer_number) {
              this.$toast("请输入纳税人识别号");
              return;
            } else if (!this.bill_content.account_bank) {
              this.$toast("请输入开户行");
              return;
            } else if (!this.bill_content.bank_account) {
              this.$toast("请输入银行账号");
              return;
            } else if (!this.bill_content.address) {
              this.$toast("请输入地址");
              return;
            } else if (!this.bill_content.mobile) {
              this.$toast("请输入电话");
              return;
            } else if (!reg.test(this.bill_content.mobile)) {
              this.$toast("请输入电话");
              return;
            } else {
              this.form.bill_content = JSON.stringify(this.bill_content);
            }
          }
        }
        const res = await this.$store.dispatch(
          "upload_payment_voucher",
          this.form
        );
        if (res.data.code == 200) {
          this.$toast(res.data.message);
          const data = await this.$store.dispatch("get_user_certificate", {
            site_id: this.$route.query.site_id,
          });
          if (data.data.code == 200) {
            this.payment_voucher = data.data.data.payment_voucher;
            this.emptyForm();
            this.$toast(res.data.message);
            this.showPayment = true;
          }
        } else {
          this.$toast(res.data.message);
        }
      }
    },
    async del_payment_voucher() {
      let that = this;
      async function beforeClose(action, done) {
        if (action === "confirm") {
          const res = await that.$store.dispatch("del_payment_voucher", {
            payment_voucher: that.payment_voucher,
          });
          if (res.data.code == 200) {
            that.payment_voucher = "";
            that.showPayment = false;
            that.$toast(res.data.message);
            done();
          } else {
            that.$toast(res.data.message);
          }
        } else {
          done();
        }
      }

      this.$dialog.confirm({
        title: "温馨提示",
        message: "是否确认注销凭证，重新生成？",
        beforeClose,
      });
    },
    goRegPayment(){
       let site_id = this.$route.query.site_id
            let module_id = this.$route.query.module_id
            this.$router.push({
                "path": "/RegPayment",
                "query": {
                    "site_id": site_id,
                    "module_id": module_id
                  
                }
            })
    }
  },
};
</script>

<style lang="less" scoped>
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.position-box {
  display: block;
  position: absolute;
  width: 60px;
  height: 40px !important;
  right: 0;
  top: 0;
  z-index: 99;
}
.invoice {
  background-color: #f3f2f7;
  // height: calc(100vh-0.44rem);
  overflow-y: scroll;
  .Payment-box {
    background-color: #fff;
    position: relative;
    height: 100%;
    font-size: 14px;
    .accomplish {
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 125px;
      height: 125px;
    }
    .barcode-cg {
      position: absolute;
      top: 185px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #333;
    }
    .barcode {
      position: absolute;
      top: 240px;
      left: 50%;
      transform: translateX(-50%);
      width: 52px;
      height: 51px;
    }
    .barcode-img {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 110px;
      width: 300px;
      top: 286px;
      // background: url('../assets/pay/txmimg.svg');
    }
    span {
      position: absolute;
      display: block;
      width: 100%;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      top: 344px;
      font-size: 14px;
      color: #333333;
    }
    .one-p {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      font-size: 14px;
      color: #333333;
      top: 425px;
    }
    .tow-p {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      font-size: 14px;
      color: #ccc;
      top: 445px;
    }
    .p-color {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      font-size: 14px;
      color: #00ccb0;
      top: 480px;
    }
    .invoice_support {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  .invoice-box {
    // position: absolute;
    // left: 0;
    // top: 0;
    height: 92%;
    overflow-y: scroll;
    padding: 15px;
    padding-bottom: 30px;
    // box-sizing: border-box;
    .hint {
      margin-bottom: 5px;
      /deep/.el-alert {
        border-radius: 10px;
        .el-icon-warning {
          &::before {
            content: "" !important;
            background: url("../assets/pay/privacy.svg");
            width: 20px;
            height: 20px;
            display: block;
            background-size: 100% 100%;
            margin-top: 4px;
          }
        }
      }
    }
    .message {
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      /deep/ .van-cell__title.van-field__label {
        color: #66656a;
      }
    }
    .uploading-img {
      background-color: #fff;
      border-radius: 10px;
      margin-top: 12px;
      position: relative;
      height: 314px;
      .upload-demo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 12px;
        width: 123px;
        height: 40px;
        position: relative;
        /deep/ .el-upload.el-upload--text {
          width: 123px !important;
          height: 40px !important;
          position: absolute;
          left: 0;
          top: 0;
          .el-button--primary {
            width: 123px !important;
            height: 40px !important;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #00ccb0;
            border: none;
            border-radius: 20px;
          }
        }
      }
      .van-uploader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 12px;
        width: 123px;
        height: 40px;
        /deep/ .van-uploader__wrapper {
          width: 123px !important;
          height: 40px !important;
          .van-uploader__input-wrapper {
            width: 123px !important;
            height: 40px !important;
            position: relative;
            .van-button {
              width: 123px !important;
              height: 40px !important;
              position: absolute;
              left: 0;
              top: 0;
              background-color: #00ccb0;
              border: none;
              border-radius: 20px;
            }
          }
        }
      }
      p {
        width: 100%;
        position: absolute;
        top: 62px;
        font-size: 13px;
        color: #666666;
        text-align: center;
      }
      .transaction {
        width: 134px;
        height: 210px;
        border: 1px dashed #c9c9c9;
        box-sizing: border-box;
        border-radius: 10px;
        position: absolute;
        top: 85px;
        left: 50%;
        transform: translateX(-50%);
        object-fit: scale-down;
      }
      .return-pay {
        position: absolute;
        left: 16px;
        bottom: 19px;
        font-size: 12px;
        color: #00ccb0;
      }
    }
    .invoice-form {
      margin-top: 15px;
      border-radius: 10px;
      overflow: hidden;
      .van-collapse {
        border-radius: 10px;
        overflow: hidden;
        //     .van-collapse-item{
        //         .van-collapse-item__wrapper{
        //             .van-collapse-item__content{
        //                 .added-form{
        //                     .van-collapse{
        //                         .van-collapse-item{
        //                             .van-collapse-item__wrapper{
        //                                 .van-collapse-item__content{
        //                                     .bankcard-content{
        //     background-color: red!important;
        // }
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
      }
      .added-form {
        /deep/ .van-collapse {
          &::after {
            border: none !important;
          }
        }
        .added-title {
          position: relative;
          .wire {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #ebedf0;
          }
        }
      }
    }
  }
  .have-paid-btn {
    position: fixed !important;
    bottom: 0px;
    left: 0;
    height: 65px !important;
    width: 100%;
    background-color: #fff;
    .van-button {
      position: fixed;
      left: 15px;
      bottom: 5px;
      border: 1px solid #00ccb0;
      background-color: #00ccb0;
      color: #fff;
      height: 54px;
      width: calc(100% - 30px);
      border-radius: 27px;
      // box-sizing: border-box;
    }
  }
}
/deep/ .van-collapse-item__content {
  padding: 10px;
}
/deep/ .van-cell::after {
  border: none;
}
/deep/ .bankcard-content {
  background-color: #f7f7f7 !important;
  padding: 10px !important;
  border-radius: 16px 10px !important;
  overflow: hidden;
  .color-box {
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
